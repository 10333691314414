import { useState, useMemo, useEffect, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Alert from 'components/Alert/Alert'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ConfirmPdlModal from './components/ConfirmPdlModal'
import ProjectWithoutPdlCard from './components/ProjectWithoutPdlCard'
import ProjectWithPdlCard from './components/ProjectWithPdlCard'
import {
	administrationProjectWithPdlMapper,
	administrationProjectWithoutPdlMapper,
} from './mappers/administration-pdl-mappers'
import { getPdlDataToTable } from './helpers/functions'
import EditPdlAllowableModal from './components/EditPdlAllowableModal'
import { getProjectsFromSales } from 'local_redux/actions/projectAction'
import { getProfilesForPDL } from 'local_redux/actions/investorProfileActions'
import { updateSigOfferDataByPdl } from 'local_redux/actions/projectAction'
import { PROJECT_FROM_SALES_LIST_RESET } from 'local_redux/constants/projectConstant'
import { PROJECT_LIQUIDITY_REGISTER_RESET } from 'local_redux/constants/projectLiquidityConstants'
import { SIG_PROJECT_UPDATE_BY_PDL_RESET } from 'local_redux/constants/projectConstant'
import styles from './styles/administrationPdlListScreenStyles'

const useStyles = makeStyles(styles)

const AdministrationPdlListScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [projectsSales, setProjectsSales] = useState([])
	const [profilesList, setProfilesList] = useState([])
	const [projectSales, setProjectSales] = useState({})
	const [dateUpdateData, setDateUpdateData] = useState(null)
	const [openConfirmPdl, setOpenConfirmPdl] = useState(false)
	const [openEditPdlAllowable, setOpenEditPdlAllowable] = useState(false)
	const [alert, setAlert] = useState(null)

	const { successProjectFromSalesList, projectFromSalesList } = useSelector((state) => state.projectsFromSales)
	const { successProjectLiquidityRegister, projectLiquidity } = useSelector((state) => state.projectLiquidityRegister)
	const { successInvestorsProfilesForPDL, loadingInvestorsProfilesForPDL, profilesForPDL } = useSelector(
		(state) => state.investorsProfileListForPDL,
	)
	const { successSigProjectUpdate } = useSelector((state) => state.sigProjectUpdatebyPdl)

	useEffect(() => {
		dispatch(getProjectsFromSales())
		dispatch(getProfilesForPDL())
	}, [])
	useEffect(() => {
		if (successProjectFromSalesList) {
			setProjectsSales(projectFromSalesList)
		}
	}, [successProjectFromSalesList])
	useEffect(() => {
		if (successInvestorsProfilesForPDL) {
			setProfilesList(profilesForPDL)
		}
	}, [successInvestorsProfilesForPDL])
	useEffect(() => {
		if (successSigProjectUpdate) {
			const projectsUpdate = projectsSales.map((project) => {
				if (project._id === dateUpdateData.id) {
					project.projectOffers = project.projectOffers.map((offer) => {
						if (offer._id === dateUpdateData.offerId) {
							offer.dateMaximumPurchase = dateUpdateData.dateMaximumPurchase || offer.dateMaximumPurchase
							offer.amountPDL = dateUpdateData.newAmount || offer.amountPDL || offer.amount
						}
						return offer
					})
				}
				return project
			})
			setProjectsSales(projectsUpdate)
			setAlert(
				<Alert
					props={{ success: true }}
					text='PDL actualizado'
					confirmSuccess={() => {
						setAlert(null)
						setDateUpdateData(null)
						dispatch({ type: SIG_PROJECT_UPDATE_BY_PDL_RESET })
					}}
				/>,
			)
		}
	}, [successSigProjectUpdate])
	useEffect(() => {
		if (successProjectLiquidityRegister) {
			const projectsUpdated = projectsSales.map((project) => {
				if (project._id === projectLiquidity.project) {
					project.projectLiquidities = [...project.projectLiquidities, projectLiquidity]
					project.user = projectLiquidity.user
					return project
				}
				return project
			})
			setProjectsSales(projectsUpdated)
			setAlert(
				<Alert
					props={{ success: true }}
					text='PDL creado correctamente.'
					confirmSuccess={() => {
						setAlert(null)
						dispatch({ type: PROJECT_LIQUIDITY_REGISTER_RESET })
					}}
				/>,
			)
		}
	}, [successProjectLiquidityRegister])
	useEffect(() => {
		return () => {
			dispatch({ type: PROJECT_FROM_SALES_LIST_RESET })
		}
	}, [])

	const editDateMaximumPurchase = (data) => {
		//{id, offerId, dateMaximumPurchase, newAmount}
		setDateUpdateData(data)
		dispatch(updateSigOfferDataByPdl(data))
	}
	const salesWithoutProjects = useMemo(() => {
		const projectsSalesWithoutPdl = getPdlDataToTable(projectsSales)
		
		return administrationProjectWithoutPdlMapper(
			projectsSalesWithoutPdl,
			setOpenConfirmPdl,
			setProjectSales,
			editDateMaximumPurchase,
			createRef,
			setOpenEditPdlAllowable,
		)
	}, [projectsSales])
	const salesWithProjects = useMemo(() => {
		const pdlProjects = []
		projectsSales.forEach((project) => {
			const projectsLiquidities = project.projectLiquidities.map((item) => {
				return {
					...item,
					project,
				}
			})
			pdlProjects.push(...projectsLiquidities)
			return project
		})

		return administrationProjectWithPdlMapper(pdlProjects)
	}, [projectsSales])

	return (
		<GridContainer>
			<GridItem xs={12} className={classes.rootItem}>
				<ProjectWithoutPdlCard salesWithoutProjects={salesWithoutProjects} />
			</GridItem>
			<GridItem xs={12} className={classes.rootItem}>
				<ProjectWithPdlCard salesWithProjects={salesWithProjects} />
			</GridItem>
			{openConfirmPdl && (
				<ConfirmPdlModal
					openConfirmPdl={openConfirmPdl}
					setOpenConfirmPdl={setOpenConfirmPdl}
					projectSales={projectSales}
					profilesList={profilesList}
					loadingProfiles={loadingInvestorsProfilesForPDL}
				/>
			)}
			{openEditPdlAllowable && (
				<EditPdlAllowableModal
					openEditPdlAllowable={openEditPdlAllowable}
					setOpenEditPdlAllowable={setOpenEditPdlAllowable}
					projectSales={projectSales}
					setDateUpdateData={setDateUpdateData}
				/>
			)}
			{alert}
		</GridContainer>
	)
}

export default AdministrationPdlListScreen
