export const getPdlDataToTable = (projectsSales) => {
	return projectsSales?.reduce((total, project) => {
		const offerAccepted = project?.projectOffers?.find((offer) => offer.isAccepted)
		const totalPdl =
			project?.projectLiquidities?.length > 0
				? project?.projectLiquidities?.reduce((sum, projectLiquidity) => sum + projectLiquidity?.amountPdl, 0)
				: 0
				
		const pdlAllowable = (offerAccepted?.amountPDL ?? offerAccepted?.amount) ? (offerAccepted?.amountPDL ?? offerAccepted?.amount) : 0

		if (totalPdl < pdlAllowable || totalPdl === 0) {
			total.push({
				...project,
				pdlAllowable,
				totalPdl,
				offerAccepted,
				pdlAvailable: pdlAllowable - totalPdl,
			})
		}
		return total
	}, [])
}
